.features {
    justify-content: space-around;
    list-style-type: none;
    padding: 30px 0 30px 30px;
    margin-bottom: 0;
    line-height: 32px;

    @media (min-width: 992px) {
        display: flex;
        padding-left: 0;
    }
}

.features-item {
    padding-left: 33px;
    background: left center no-repeat url('/img/li2.svg');
    font-weight: 500;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}
