.section-wrapper {
    background-color: #eceef1;
    padding: 52px 0;

    @media (min-width: 768px) {
        padding: 94px 0 77px;
    }
}

.section {
    padding: 53px 30px 50%;
    background-color: #fff;
    background-repeat: no-repeat;

    @media (min-width: 768px) {
        padding-bottom: 77px;
    }
}

.section-item {
    margin-bottom: 1.5rem;
    position: relative;
    left: 15px;

    @media (min-width: 768px) {
        left: auto;
        position: initial;
        margin-bottom: 0;
    }
}

.section-first {
    background-image: url('/img/s1-2.png'), url('/img/s1-1.svg');
    background-position: right 95%, left bottom;
}

.section-second {
    background-image: url('/img/s2-2.png'), url('/img/s2-3.svg');
    background-position: right 95%, left bottom;

    @media (min-width: 768px) {
        background-image: url('/img/s2-2.png'), url('/img/s2-1.svg');
        background-position: right 95%, right top;
    }
}
