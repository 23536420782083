.carcard {
    margin-bottom: 40px;
}

.carcard-image-wrap {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 218px;

    @media (min-width: 992px) {
        border-radius: 8px 8px 0 0;
    }
}

.card-image-img {
    object-fit: cover;
}

.carcard-image-infoline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    display: flex;
}

.carcard-image-action-buttons {
    display: inline-flex;

    &:hover .carcard-image-btn {
        background-color: var(--bs-secondary);
    }

    .carcard-image-btn:first-child {
        border-bottom-left-radius: 8px;
    }

    .carcard-image-btn:hover {
        background-color: var(--bs-secondary);
        filter: brightness(95%);
    }
}

.carcard-image-prevnext {
    display: flex;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    z-index: 2;

    .carcard-image-btn:first-child {
        border-top-right-radius: 8px;
    }

    .carcard-image-btn:last-child {
        border-top-left-radius: 8px;
    }
}

.carcard-image-btn-prev {
    background-color: #fff;
}

.carcard-image-btn {
    pointer-events: auto;
    height: 44px;
    width: 44px;
    border: none;
    background-color: rgba(182, 193, 204, 0.3);

    &:hover {
        background-color: var(--bs-secondary);
    }
}

.carcard-desc-wrap {
    padding: 24px 0 20px 0;
}

.carcard-price-wrap {
    padding: 18px 16px;
    background-color: #eceef1;
    border-radius: 8px;
    margin-bottom: 15px;
}

.carcard-price-current {
    font-weight: 700;
    margin-right: 10px;
}

.carcard-price-start {
    text-decoration: line-through;
    text-decoration-color: $gray;
}

.card-desc-title {
    margin-bottom: 8px;
}

.carcard-readmore {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
}

.carcard-2images {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.carcard-v1 {
    height: 492px;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    line-height: 1;
    margin-bottom: 40px;
}

.carcard-v1-image {
    height: 280px;
    width: 100%;
    object-fit: cover;
}

.carcard-v1-body {
    padding: 76px 30px 30px;
    background-image: url('/img/b4-2.svg'), url('/img/b4-1.svg');
    background-repeat: no-repeat;
    background-position: left bottom, left bottom;
    background-size: 106%, 106%;
    position: relative;
    top: -65px;
    height: 277px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.carcard-v2 {
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 40px;
}

.carcard-v2-image {
    background-image: url('/img/b5-1.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 307px;
    line-height: 1;
    background-color: #e9e9e9;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        position: absolute;
        background-image: url('/img/b5-2.svg');
        background-repeat: no-repeat;
        top: 163px;
        right: -150px;
        pointer-events: none;
        height: 100%;
    }
}

.carcard-v2-body {
    background-color: #eceef1;
    height: 190px;
    padding: 21px 30px 30px;
    line-height: 1;
}

.carcard-v3 {
    background-color: rgba(182, 193, 204, 0.3);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 40px;
}

.carcard-v3-image {
    height: 279px;
}

.carcard-v3-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 30px 30px;
    line-height: 1;
    height: 213px;
}

.carcard-v4 {
    color: #fff;
    background-image: url('/img/hero-bg.webp');
    padding: 37px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    border-radius: 8px;
    overflow: hidden;
    height: 218px;
}

.carcard-v6 {
    overflow: hidden;
    background-color: rgba(158, 171, 184, .2);
    background-image: url(/img/b6.svg), url(/img/b62.webp);
    background-size: contain;
    background-position: top center, right bottom;
    background-repeat: no-repeat;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: calc(100vw - 30%);

    @media (min-width: 768px) {
        padding-bottom: 250px;
    }

    @media (min-width: 992px) {
        margin: initial;
        border-radius: 8px;
    }
}

.carcard-v6-body {
    padding: 39px 34px 0;
}

.carcard-v6-body p:last-child {
    margin-bottom: -5px;
}

.carcard-side {
    display: none;

    @media (min-width: 768px) {
        position: absolute;
        top: 0;
        bottom: 0;
        width: auto;
        height: 100%;
        z-index: 4;
        display: block;
        width: 150px;
    }
}

.carcard-side-right {
    right: 0;

    @media (min-width: 992px) {
        background-image: linear-gradient(to right, transparent, #fff 80%);
    }
}

.carcard-side-left {
    left: 0;

    @media (min-width: 992px) {
        background-image: linear-gradient(to left, transparent, #fff 80%);
    }
}

.carcard-side-button {
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    width: 41px;
    height: 100%;
}

.carcard-side-left .carcard-side-button {
    background-image: url(/img/arrow-left-dark.svg);
    float: left;
}

.carcard-side-right .carcard-side-button {
    background-image: url(/img/arrow-right-dark.svg);
    float: right;
}

.carcard-side-button.swiper-button-disabled {
    visibility: hidden;
}

.carcard-wrapper {
    width: 330px !important;
    max-width: 100%;
}

.carcard-wrapper:nth-child(n+4) {
    display: none;
}

@media (min-width: 768px) {
    .carcard-wrapper:nth-child(n+4) {
        display: block;
    }
}
