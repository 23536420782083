.tags-sm {
    --tag-multiplier: 0.85;
}

.tag {
    background-color: var(--bs-secondary);
    border-radius: 30px;
    padding: calc(4px * var(--tag-multiplier, 1)) calc(12px * var(--tag-multiplier, 1));
    margin: 0 8px 8px 0;
    display: inline-block;
    font-size: calc(1em * var(--tag-multiplier, 1));
    cursor: pointer;

    &, & a, &:hover, &:hover a {
        color: #fff;
        text-decoration: none;
        white-space: nowrap;
    }

    &:hover {
        background-color: darken($secondary, 10%);
    }

    img {
        vertical-align: text-top;
    }
}

.tag.bg-opacity {
    --bs-bg-opacity: .15;
}

.tag-selected,
.tag-selected:hover {
    padding-left: 30px;
    background-image: url("/img/check-white.svg");
    background-size: 16px;
    background-position: 10px calc(50% + 1px);
    background-repeat: no-repeat;
    background-color: $primary;
}

.tag:not(.tag-closable) .tag-close {
    display: none;
}

.tag-close {
    background-image: url('/img/close-white.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    padding: 0;
    margin-right: -3px;

    &:hover {
        background-color: #fff3;
    }
}
