.about-preview {
    background-image: url('/img/about-heading.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.about-heading {
    font-size: 20px;

    @media (min-width: 768px) {
        font-size: 38px;
    }
}

.about-items-heading {
    font-size: 32px;
    font-weight: bold;
}

.about-items-text {
    font-size: 14px;
}

.about-header-box {
    background-image: url('/img/about-bg-2.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 135% auto;

    @media (min-width: 768px) {
        background-size: auto;
    }
}
