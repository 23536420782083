.car-banner {
    background-image: url(/img/b7-2.svg), url(/img/hero-bg.webp);
    background-repeat: no-repeat;
    background-position: 200% bottom, right 10%;
    background-size: contain, cover;
    border-radius: 8px;
    padding: 26px;
    color: #fff;

    @media (min-width: 768px) {
        background-image: url(/img/b7-1.svg), url(/img/hero-bg.webp);
        background-position: right center, right 10%;
        background-size: contain, 165%;
    }
}

.car-banner-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px;
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
    }
}

.car-banner-description {
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
}

.car-banner-card {
    color: var(--bs-body-color);
    border-radius: 8px;
    background-color: #fff;
    padding: 15px 20px;
    display: none;
    position: absolute;
    width: 288px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @media (min-width: 768px) {
        display: block;
    }
}

.car-banner-rating-star {
    vertical-align: revert;
}

.car-banner-rating-max {
    position: relative;
    top: -7px;
}

.car-banner-salon {
    font-size: 18px;
}

.car-bannder-btns {
    display: flex;
    flex-direction: column;

    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.car-banner-btn:hover {
    background: rgba(255, 255, 255, 0.2);
}
