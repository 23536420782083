.infobox {
    border: 1px solid #F4F6F8;
    border-radius: 8px;
    padding: 24px 18px;
    margin-bottom: 1em;

    @media (min-width: 768px) {
        margin-bottom: 1.5em;
    }
}
