@import "bootstrap";
// @import "~bootstrap";

// Template
@import "ui.scss";
@import "base.scss";
@import "navbar.scss";
@import "footer.scss";
@import "hero.scss";
@import "carcard.scss";
@import "custom-utilities.scss";
@import "breadcrumbs.scss";
@import "feedback.scss";
@import "bodytype.scss";
@import "features.scss";
@import "section.scss";
@import "tags.scss";
@import "credit.scss";
@import "modal.scss";
@import "creditcond.scss";
@import "car-buttons.scss";
@import "car-banner.scss";
@import "used-cars.scss";
@import "news.scss";
@import "infobox.scss";
@import "partners.scss";
@import "insurance.scss";
@import "car.scss";
@import "test-drive.scss";
@import "tradein.scss";
@import "about.scss";
@import "commission-sale.scss";
@import "spinner.scss";
@import "order-form.scss";
@import "additionalStyles.scss";

@import "_nouislider.scss";
@import "~select2/dist/css/select2.css";
@import "_select2.scss";
