.modal {
    color: #fff;
    background-color: rgba(21, 32, 38, .9);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.modal.open {
    display: flex;
}

.modal-close {
    width: 58px;
    height: 58px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    border: none;
    font-size: 36px;
    padding: 0;
    color: #fff;
    background-image: url("/img/close.svg");
    color: transparent;
    background-size: 26px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 5%;
    bottom: 100%;
    margin: 0 0 25px 25px;

    @media (min-width: 992px) {
        top: 0;
        right: 0;
        bottom: auto;
        width: 38px;
        height: 38px;
    }
}

.modal-container {
    position: relative;
    padding: 45px 15px;
}

.modal-btn {
    padding-left: 15%;
    padding-right: 15%;
}
