.imgCheck {
    margin-right: 9.59px;
}

ul,
li {
    list-style-type: none;
}

.group14 {
    color: #fff;
    background-image: url(/img/group14.png);
    background-repeat: no-repeat;
    height: 278px;
    width: 464px;
    border-radius: 0px;
}

.group15 {
    color: #fff;
    background-image: url(/img/group15.png);
    background-repeat: no-repeat;
    height: 278px;
    width: 464px;
    border-radius: 0px;
}

.linkRed {
    color: $primary;
    text-decoration: none;
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 32px;
    margin-left: 44px;
}

.headingTwoSections {
    margin-top: 70px;
    margin-left: 44px;
    margin-bottom: 14px;
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
}

.paragrTwoSections {
    margin-left: 44px;
    margin-bottom: 20px;
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
}

.poTipuKuzova {
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #152026;
    margin-top: 265px;
    margin-bottom: 16px;
}

.liPoTipuKuzova {
    color: gray;
    text-decoration: none;
    list-style: none;
    margin-left: -27px;
    line-height: 22px;
    margin-bottom: 10px;

}

.liPoTipuKuzova>a {
    color: gray;
    text-decoration: none;
    list-style: none;
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

#tableGetUiKit {
    text-align: right;
    padding-right: 62px;
}

// compare
.compare_specifications__title {
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: #363e42;
    text-align: left;
    margin-right: 80px;
    white-space: nowrap;

    @media (min-width: 768px) {
        margin-left: 40%;
    }
}

.compare_specifications__description {
    font-family: 'Commissioner';
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    color: #152026;
    text-align: left;
    margin-left: 20px;
    margin-right: 80px;
    white-space: nowrap;
}

.compare_specification__white {
    background-color: #F8F8F8;
    border: solid 1px #E5E5E5;
}

.compare_specifications_image {
    width: 185px;
    height: 129px;
    border-radius: 8px;
    margin-right: 22px;
    object-fit: contain;
}

.compare_specification_close {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 2rem;
    line-height: 1;
    position: absolute;
    right: 10px;
    font-weight: bold;
    text-shadow: 0 0 1px #000, 0 0 1px #000;
    top: 0;
}

#globalSearchInputDiv {
    display: none;
}

#prodati {
    display: none;
}

#ourOffer-TwoSections {
    display: none;
}

#ourOffer-title {
    display: none;
}

#sendOrderTwoButtons {
    display: none;
}

// modal отправить данные дилеру начало


/* Modal Content/Box */
.modal-content {
    background-color: black;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.911);
    height: 100%;
    width: 100%;
    /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

// modal отправить данные дилеру конец

// vertical scrollable div
div.scrollmenu {
    background-color: rgb(88, 88, 88);
    overflow: auto;
    white-space: nowrap;
}

div.scrollmenu a {
    display: inline-block;
    color: white;
    padding: 14px;
    text-decoration: none;
}

div.scrollmenu a:hover {
    border: solid 1px rgb(88, 88, 88);
}

// additional additional styles

#mobile {
    display: block;
}

#tablet {
    display: none;
}

#desktop {
    display: none;
}

@media only screen and (min-width: 600px) {
    #mobile {
        display: none;
    }

    #tablet {
        display: block;
    }

    #desktop {
        display: none;
    }
}

@media only screen and (min-width: 1300px) {
    #mobile {
        display: none;
    }

    #tablet {
        display: none;
    }

    #desktop {
        display: block;
    }
}

.setelemScrollMenu {
    border-radius: 8px;
    background-color: #2C363C;
    margin-right: 10px;
    height: 207px;
    width: 150px;
    margin-bottom: -32px;
    padding-bottom: 10px;
}
