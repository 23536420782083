.car-estimate {
    width: 100%;
    height: 6px;
    background-image: linear-gradient(to right, #6FCF97, #FFC875, $primary);
}

.car-estimate-pointer {
    width: 12px;
    height: 12px;
    border: 2px solid #ECEEF1;
    background-color: #9EABB8;
    top: -3px;
    border-radius: 100%;
    position: relative;
    transform: translateX(-6px);
}

.car-equip {
    text-align: left;
    width: 100%;
    background-color: #f8f9fa;
    color: #152026;
    font-size: 18px;
    font-weight: 500;
    border-radius: 8px;
    padding: 0.6rem 0.75rem 0.6rem 25px;
}

.car-equip-advantage {
    padding-left: 25px;
    text-align: left;
    width: 100%;
    background-color: #ffffff;
    border: solid 1px #f8f9fa;
    color: #6FCF97;
    font-size: 18px;
    font-weight: 500;
    border-radius: 8px;
}

.car-equip-body {
    padding: 32px;
    height: 100%;
    border-radius: 8px;
    background-image: url('/img/car-equip-body.svg'), url('/img/car-equip-body.webp');
    background-repeat: no-repeat;
    background-size: 100% auto, cover;
    background-position: top left, center center;
}

.car-sticky-price_wrapper {
    position: fixed;
    top: -130px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    transition: top linear .4s;
}

.car-sticky-price_wrapper-visible {
    top: 0;
}

.car-sticky-price {
    background-image: url(/img/hero-bg.webp);
    background-position: right 10%;
    background-size: 165%;
    color: #fff;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.car-sticky-price_left {
    padding: 18px 0 18px 30px;
}

.car-sticky-price_right {
    padding: 18px 30px;
    background-color: var(--bs-primary);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        border: 62px solid transparent;
        width: 1px;
        height: 1px;
        border-right: 70px solid var(--bs-primary);
        border-top-width: 52px;
        border-bottom-width: 72px;
    }
}

.car-slider,
.car-slider-gallery,
.car-slider-gallery .swiper-slide {
    overflow: hidden;
    border-radius: 8px;
}

.car-slider {
    margin-bottom: 15px;
}

.car-slider-gallery-next,
.car-slider-gallery-prev {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 38px;
    background-color: #fff;
    z-index: 2;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.car-slider-gallery-prev {
    left: 0;
}

.car-slider-gallery-next {
    right: 0;
}

.swiper-button-disabled {
    visibility: hidden;
}
