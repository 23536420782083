.breadcrumbs {
    list-style-type: none;
    padding: 16px 0;

    & > li {
        display: inline-block;
    }

    & > li:not(:last-child):after {
        content: '/';
    }

    & > li > a {
        text-decoration: none;
    }

    & > li:last-child {
        text-decoration: underline;
    }
}

.breadcrumbs a {
    color: var(--bs-body-color);
}

.breadcrumbs-white a {
    color: #fff;
}
