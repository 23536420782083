.form-recordselection {
    background-image: url(/img/b-tradein.webp), linear-gradient(90deg, #ECEEF1 8.28%, rgba(236, 238, 241, 0) 100%);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: calc(50px + 53vw);
    padding-bottom: 50px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .form-recordselection {
        background-image: linear-gradient(90deg, #ECEEF1 8.28%, rgba(236, 238, 241, 0) 100%);
    }
}

@media (min-width: 768px) {
    .form-recordselection {
        background-size: auto 100%;
    }
}
