.insurance-bg {
    background-image: url(/img/insurance-bg-1.svg), url(/img/insurance-bg-2.svg);
    background-position: top left, top right;
    background-repeat: no-repeat;
    background-size: 60%, 0;
}

@media (min-width: 992px) {
    .insurance-bg {
        background-size: auto;
    }
}

.insurance-wrapper-sm {
    background-color: #9EABB819;
}

@media (min-width: 992px) {
    .insurance-wrapper-sm {
        background-color: transparent;
    }

    .insurance-wrapper-lg {
        background-color: #9EABB819;
        border-radius: 8px;
    }
}
