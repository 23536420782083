.site-footer {
    --bs-body-color: #fff;
    --link-color: var(--bs-body-color);

    background-color: var(--bs-dark);

    padding-top: 47px;
    padding-bottom: 26px;
    color: var(--bs-body-color);

    @media (min-width: 992px) {
        padding-top: 54px;
        padding-bottom: 71px;
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }

    header {
        margin-bottom: 16px;
    }
}

.footer-brand-subtitle {
    margin: 15px 0 40px;
}

.footer-nav {
    list-style: none;
    line-height: 1.57em;
    padding: 0;
    margin: 0 0 20px;

    a {
        opacity: .5;

        &:hover {
            opacity: .8;
        }
    }
}

.footer-nav-item {
    margin-bottom: 10px;
}

.footer-nav-social a {
    margin-right: 9px;
}

@media (min-width: 992px) {
    .footer-logo-wrapper {
        width: 126px;
        flex: 0 0 126px;
    }
}
