.news-item,
.news-item:hover {
    color: inherit;
    text-decoration: none;
    margin-bottom: 47px;
}

.news-item-image {
    width: 100%;
    height: 218px;
    object-fit: cover;
    margin-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
}

.news-item-tags {
    height: 30px;
    margin-bottom: 8px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 15px;
        height: 100%;
        background-image: linear-gradient(to right, transparent, #fff);
        z-index: 2;
    }
}

.news-item-tag {
    padding: 3px 11px;
    color: #fff;
    background-color: #9EABB8;
    border-radius: 20px;
    display: inline-block;
    width: auto !important;
}

.news-item-desc {
    position: relative;
    color: rgb(21, 32, 38, .5);
    line-height: 22px;
    max-height: 44px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
