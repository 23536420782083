// h1, h2, h3, h4, h5, h6 {
//     color: var(--bs-body-color);
// }

.page-header,
.h3 {
    font-size: $h3-font-size;
}

h6, .h6 {
    font-size: $h6-font-size;
    font-weight: 500;
    line-height: 30px;
}

.subtitle-1 {
    font-weight: 700;
    font-size: 20px;
    // color: var(--bs-body-color);
}

.subtitle-2 {
    font-weight: 400;
    font-size: 20px;
    // color: var(--bs-body-color);
}
.subtitle-3 {
    font-weight: 700;
    font-size: 18px;
    // color: var(--bs-body-color);
}

a,
.link-1,
.link-2 {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    cursor: pointer;
    color: var(--link-color, inherit);

    &:hover {
        color: var(--link-color, inherit);
    }
}

.link-1 {
    font-size: 18px;
}

.link-2 {
    font-size: 1rem;
}

label,
.label {
    font-weight: bold;
}

.input,
.select,
.input-group,
.select.select2 + .select2 {
    background-color: var(--input-group-bg, #fff);
    color: var(--input-group-color, inherit);
    height: var(--input-group-height, 38px);
}

textarea.input {
    height: var(--input-group-height, 95px);
}

.input-sm,
.select-sm,
.input-group-sm,
.select-sm.select2 + .select2 {
    --input-group-height: 32px;
}

.btn-sm {
    height: 32px;
}

textarea.input-sm {
    --input-group-height: 80px;
}

.input-lg,
.select-lg,
.input-group-lg,
.select-lg.select2 + .select2 {
    --input-group-height: 44px;
}

.btn-lg {
    height: 44px;
}

textarea.input-lg {
    --input-group-height: 110px;
}

.input-gray,
.select-gray,
.input-group-gray,
.select-gray.select2 + .select2 {
    --input-group-bg: rgba(51, 51, 51, 0.1);
    --input-group-color: $body-color;
}

.input-transparent,
.select-transparent,
.input-group-transparent,
.select-transparent.select2 + .select2 {
    --input-group-bg: rgba(255, 255, 255, 0);
    --input-group-color: #fff;
}

.input-group-transparent {
    background-color: rgba(255, 255, 255, .1);
}

.select-gray > option,
.input-group-gray .select > option {
    background-color: #fff;
}

.input-group {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    border-radius: 8px;
    height: var(--input-group-height, 38px);
    color: var(--input-group-color, --bs-body-color);
}

.input,
.select,
.input-addon,
.select.select2 + .select2 {
    height: var(--input-group-height, 38px);
    padding: 9px;
    background-color: var(--input-group-bg, #fff);
    color: var(--input-group-color, var(--bs-body-color));
    border-radius: 8px;
    border: 0 solid transparent;
    line-height: 1;
}

.select.select2 + .select2 {
    vertical-align: middle;
    padding: 0;
}

.select.select2 + .select2 .select2-selection {
    padding: 16px 15px 0;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.input-addon {
    color: var(--input-group-color, var(--bs-body-color));
    line-height: calc(var(--input-group-height) - 20px);
}

.input,
.select {
    flex: 1 1 auto;
}

.input-group {
    & > * {
        border-radius: 0;
    }

    & > *:first-child {
        border-radius: 8px 0 0 8px;
    }

    & > *:last-child {
        border-radius: 0 8px 8px 0;
    }
}

.big {
    font-size: 18px;
    line-height: 32px;
}

small,
.small {
    font-size: 12px;
    line-height: 18px;
}

.table-lines {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
}

//     tr {
//         margin-bottom: 8px;
//         padding-bottom: 8px;
//     }

//     th, td {
//         padding: 16px 19px;
//         margin-bottom: 8px;
//         font-weight: 500;
//         font-size: 18px;
//     }

//     td {
//         color: rgba(21, 32, 38, .5);
//     }

//     th {
//         color: var(--bs-body-color);
//     }

//     tbody th,
//     tbody td {
//         background-color: rgba(158, 171, 184, .1);

//         &:first-child {
//             border-radius: 8px 0 0 8px;
//         }

//         &:last-child {
//             border-radius: 0 8px 8px 0;
//         }
//     }
// }

.table-row {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 8px;

    @media (min-width: 768px) {
        *:first-child {
            border-radius: 8px 0 0 8px;
            padding-left: 19px;
        }

        *:last-child {
            border-radius: 0 8px 8px 0;
            padding-right: 19px;
        }
    }
}

a.table-row {
    background-color: rgba(158, 171, 184, .1);
    border-radius: 8px;
    color: var(--bs-body-color);
    text-decoration: none;

    &:hover {
        background-color: rgba(158, 171, 184, .2);
    }
}

.table-row .opacity {
    color: rgba(21, 32, 38, .5);
}

.bg-light {
    background-color: rgba(158, 171, 184, .1);
}
