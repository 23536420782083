.bodytype {
    display: flex;
    gap: 12px;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0 0 32px;
}

.bodytype-item {
    min-width: 160px;
}

.bodytype-link {
    display: block;
    color: inherit;
    height: 80px;
    text-decoration: none;
    font-weight: bold;
    padding: 8px 12px;
    background-size: contain;
    background-repeat: no-repeat;
}
