.select.select2 + .select2 .select2-selection {
    padding: 8px;
    border-radius: 8px;
    border: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    bottom: 1px;
    margin-top: auto;
    margin-bottom: auto;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    //
}

/*
.select2 {
    cursor: default;
    display: inline-block;
}

.select2 .selection {
    display: block;
    width: 100%;
    height: 100%;
}

.select2-hidden-accessible {
    display: none;
}

.select2-selection__arrow {
    background-image: $form-select-indicator;
    background-size: $form-select-bg-size;
    margin-left: auto;
    width: 5px;
    height: 5px;
}

.select2-dropdown {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 8px;
}

.select2-search__field {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px 15px;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.select2-results__options {
    list-style: none;
    padding: 6px 0;
    margin: 0;
}

.select2-results__option {
    padding: 4px 15px;
    cursor: default;
}

.select2-results__option--highlighted {
    background-color: #eee;
}

.select2-results__option--disabled {
    color: #999;
}
*/
