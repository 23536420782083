.car-buttons {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 15px;

    @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
        left: 0;
    }
}

.car-buttons-btn {
    color: var(--bs-body-color);
    font-weight: 700;
    background: transparent;
    padding: 10px;
    border: none;
    text-align: left;
    border-bottom: 1px solid rgba(158, 171, 184, 0.2);

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        color: var(--bs-body-color);
        background-color: rgba(158, 171, 184, .2);
    }

    @media (min-width: 992px) {
        border-radius: 8px;
        padding: 6px 10px;
        margin-bottom: 0;
        border-bottom: none;
    }
}
