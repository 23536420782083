.order-form {
    position: relative;
}

.order-form.order-form--loading::before {
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    background-color: rgba(0,0,0,.5);
    display: block;
    content: 'Загрузка...';
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    z-index: 100;
    text-shadow: 0 0 3px #000;
    animation: loading-ellipsis 2s linear infinite;
}

@keyframes loading-ellipsis {
    0% { content: 'Загрузка' }
    20% { content: 'Загрузка.' }
    40% { content: 'Загрузка..' }
    60% { content: 'Загрузка...' }
}
