.reset-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-stretch {
    flex: 1 0 auto;
}

.flex-width-sm {
    flex: 0 1 auto !important;
    width: auto !important;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.nolink {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

@media (max-width: 991px) {
    .hidden-sm {
        display: none;
    }
}

@media (min-width: 992px) {
    .hidden-md {
        display: none;
    }
}

.hr-dark {
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
}

.flex-auto {
    flex: 1 1 auto;
    width: auto;
}

.mb-6 {
    margin-bottom: 60px;
}

.mb-7 {
    margin-bottom: 120px;
}

.hr-sm {
    width: 40px;
    height: 0;
    border: 2px solid var(--bs-primary);
    opacity: 1;
    border-radius: 40px;
}
