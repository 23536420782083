.feedback {
    --link-color: var(--bs-body-color);
    background-color: #eceef1;
    --form-bg: var(--form-bg-dark-opacity);
    background-image: url('/img/b-l.svg'), url('/img/b-r.svg');
    background-repeat: no-repeat;
    background-position: left 150%, calc(100% + 150px) -110px;
    padding: 80px 0;

    @media (min-width: 992px) {
        background-position: left bottom, right top;
        padding: 60px 0;
    }
}

.feedback-heading-wrapper {
    margin-bottom: 19px;
    text-align: center;

    @media (min-width: 992px) {
        width: 70%;
        margin: 10px auto 36px;
    }
}

.feedback-policy {
    width: 70%;
    margin: 30px auto 0;
    text-align: center;

    @media (max-width: 991px) {
        display: none;
    }
}

.feedback-box {
    background-color: #182329;
    background-image: linear-gradient(31.01deg, rgba(0, 0, 0, 0.6) 24.66%, rgba(51, 51, 51, 0) 76.34%), url('/img/feedback-car-bag.webp');
    background-position: right center;
    background-repeat: no-repeat;
    border-radius: 8px;
    min-height: 300px;
}

.feedback-items {
    position: relative;
    display: flex;
    justify-content: space-between;
    top: -50px;

    @media (min-width: 992px) {
        top: -110px;
    }
}

.feedback-item {
    border-radius: 10px;
    box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
    position: relative;
    display: flex;
    align-items: center;
}

.feedback-item::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 11px solid transparent;
    border-top: 15px solid #fff;
    box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
    display: block;
}

.feedback-avatar {
    height: 56px;
    width: 56px;
    border-radius: 56px;
}
