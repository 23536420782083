.credit {
    background-image: linear-gradient(180deg, var(--bs-primary) 160px, var(--bs-dark) 160px);
    color: #fff;
    min-height: calc(45vw * 0.64 + 250px);

    @media (min-width: 768px) {
        background-image: url('/img/redkia.jpg'), linear-gradient(90deg, var(--bs-primary) 45%, var(--bs-dark) 45%);
        background-size: 45%, 100%;
        background-position: left bottom, center;
        background-repeat: no-repeat;
        padding: 82px 0;
    }
}
