.commission-sale-hero {
    background-image: linear-gradient(rgba(21, 32, 38, 0.7) 0%, rgba(21, 32, 38, 0.7) 100%), url('/img/commission-sale-hero.webp');
    background-size: cover;
    background-position: center;
    padding-top: 146px;
}

.comission-sale-screen-2 {
    background-image: url('/img/comission-sale-bg-1.svg'), url('/img/comission-sale-bg-2.svg');
    background-size: 30%, auto;
    background-position: right top, left bottom;
    background-repeat: no-repeat;
}

.commission-sale-h3 {
    font-size: 28px;
    font-weight: bold;

    @media (min-width: 768px) {
        font-size: 38px;
    }
}

.commission-sale-faq {
    background-color: #ECEEF1;
    text-align: left;
    color: var(--bs-body-color);
    padding: 10px 66px 10px 24px;
    font-size: 18px;
    margin-left: -15px;
    margin-right: -15px;
    line-height: 32px;
    font-weight: 500;
    border-radius: 0;

    @media (min-width: 768px) {
        margin-left: 0;
        margin-right: 0;
        border-radius: 8px;
        width: 100%;
    }
}

.commission-sale-faq:hover {
    color: var(--bs-body-color);
    background-color: darken(#ECEEF1, 5%);
}

.commission-sale-faq-img {
    background-image: url('/img/commission-sale-bg-4.svg'), url('/img/commission-sale-bg-3.webp');
    background-size: 90%, cover;
    background-position: left top, center center;
    background-repeat: no-repeat;
    font-size: 28px;
    font-weight: bold;
    border-radius: 8px;
    height: 290px;
    margin-left: -11px;
    margin-right: -11px;

    @media (min-width: 768px) {
        font-size: 42px;
        height: 516px;
        margin-left: 0px;
        margin-right: 0px;
    }
}
