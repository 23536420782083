.top-notification {
    text-align: center;
    padding: 15px;

    a {
        text-decoration: none;
    }
}

.navbar {
    height: var(--navbar-height, 77px);
    position: relative;
    z-index: 5;

    @media (min-width: 992px) {
        background-color: rgba(255,255,255,.05);
        height: var(--navbar-height, 68px);
    }
}

.navbar-bg {
    background-color: var(--bs-body-color);
}

.navbar-nobg {
    margin-bottom: -143px;

    @media (min-width: 992px) {
        margin-bottom: -134px;
    }
}

.navbar-logo {
    margin-right: 25px;
    flex: 1 0 0;

    @media (min-width: $menu-expanded-end-width) {
        flex: 0 1 auto;
    }
}

.navbar-container {
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-toggler {
    padding: 5px;
    @include font-size($navbar-toggler-font-size);
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.1);
    border: $border-width solid transparent;
    @include transition($navbar-toggler-transition);
    border-radius: 8px;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 0 $navbar-toggler-focus-width;
    }

    @media (min-width: $menu-expanded-end-width) {
        display: none;
    }
}

.navbar-toggler-icon {
    display: inline-block;
    vertical-align: middle;
    width: 1.5em;
    height: 1.5em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: $navbar-dark-toggler-icon-bg;
}

.navbar-slide-menu {
    flex: 1 0 auto;

    @media (max-width: calc(#{$menu-expanded-end-width} - 1px)) {
        position: fixed;
        left: 100%;
        transition: left .2s ease-out;
        background: var(--bs-dark);
        width: 100%;
        height: 100vh;
        top: 0;
        bottom: 0;
        padding: 0 30px 20px;
        font-size: 22px;
        line-height: 55px;
        z-index: 5;
    }
}

.navbar-slide-menu-open {
    left: 0;
    overflow-y: auto;
}

.navbar-menu {
    text-align: center;

    @media (min-width: $menu-expanded-end-width) {
        text-align: left;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: static;
    }
}

.navbar-item {
    @media (min-width: $menu-expanded-end-width) {
        height: 100%;
    }
}

.navbar-item a {
    display: inline-flex;
    align-items: center;
    color: #fff;
    opacity: .7;
    text-decoration: none;
    transition: opacity .4s;

    @media (min-width: $menu-expanded-end-width) {
        padding: 0 5px;
        height: 100%;
    }
}

.navbar-item {
    opacity: 1;
}

.dropdown-item {
    color: var(--bs-body-color) !important;
    padding: 5px 10px !important;
    font-size: 18px;
    justify-content: center;

    @media (min-width: $menu-expanded-end-width) {
        font-size: 14px;
        justify-content: initial;
    }
}

@media (min-width: $menu-expanded-end-width) {
    .nav-link {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

.city-select {
    background-color: #fff;
    border-radius: 8px;
    padding: .5rem;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    margin-left: -25px;
    margin-right: -25px;

    @media (min-width: $menu-expanded-end-width) {
        position: absolute;
        top: 100%;
        right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}
