.hero {
    color: #fff;
    background-image: url(/img/hero-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: calc(0px - var(--navbar-height));
    padding-top: calc(62px + var(--navbar-height));
    padding-bottom: 20px;
    min-height: 80vh;
    // height: 800px;
    // max-height: 100vh;
    background-blend-mode: multiply;
    background-color: rgba(21, 32, 38, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hero-search {
    justify-content: space-between;
    display: flex;
    gap: 8px;
    position: relative;
}

.hero-search-button {
    width: 38px;
    padding: 3px 9px 6px;
}

.hero-search-input {
    flex: 2 0 auto;
    width: auto;
    max-width: 100%;
}

.hero-search-part {
    flex: .5 0 auto;
}

.search-brands {
    background-color: var(--bs-body-color);
    border-radius: $border-radius;
    position: absolute;
    top: calc(10px + 100%);
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;
    z-index: 2;
    margin-left: -20px;
    margin-right: -20px;

    @media (min-width: $menu-expanded-end-width) {
        padding: 15px;
        flex-direction: row-reverse;
        background-color: #fff;
        margin-left: 0;
        margin-right: 0;
    }
}

.search-brands-logos {
    width: calc(100% / 3);
    display: flex;
    margin: 20px 0;

    @media (min-width: $menu-expanded-end-width) {
        flex-wrap: wrap;
        margin: 0 -5px -5px;
    }
}

.search-brands-list {
    width: 100%;
    display: block;


    @media (min-width: 992px) {
        width: calc(100% / 3 * 2);
        display: flex;
        justify-content: space-between;
        gap: 7px;
    }
}

.search-brands-column {
    padding: 0 20px;
    background-color: #fff;

    &:first-child {
        border-radius: $border-radius $border-radius 0 0;
        padding-top: 20px;
    }

    &:last-child {
        border-radius: 0 0 $border-radius $border-radius;
        padding-bottom: 20px;
    }

    @media (min-width: $menu-expanded-end-width) {
        background-color: rgba(158, 171, 184, .1);
        border-radius: $border-radius !important;
        padding: 20px 23px 20px 15px;
        width: calc(100% / 3);
    }
}

.search-brands-ulist {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    li {
        display: flex;
        justify-content: space-between;
    }
}

.search-brands-card {
    background-color: #fff;
    border-radius: $border-radius;
    border: 1px solid #F4F6F8;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: 50px;
    justify-content: space-between;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    width: 90px;

    @media (min-width: $menu-expanded-end-width) {
        height: 127px;
        padding: 28px 10% 9px;
        width: calc(50% - 5px);
        margin: 0 2.5px 5px;
    }
}

.search-brands-image {
    height: 100%;
    width: 100%;
    object-fit: contain;

    @media (min-width: $menu-expanded-end-width) {
        height: 57px;
    }
}

.search-brands-name {
    display: none;
    color: #333333;
    font-size: 12px;

    @media (min-width: $menu-expanded-end-width) {
        display: block;
    }
}

.direction-toggler {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-flex;
    border-radius: 20px;

    @media (min-width: 992px) {
        width: auto;
    }
}

.direction-toggler::before {
    content: '';
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $primary;
    border-radius: inherit;
    transition-property: left, width;
    transition-duration: .15s;
    z-index: 0;

    @media (min-width: 992px) {
        width: 47%;
    }
}

.direction-toggler.toggle::before {
    width: 50%;
    left: 50%;

    @media (min-width: 992px) {
        left: 46%;
        width: 54%;
    }
}

.btn-hero-container {
    @media (min-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.btn-hero-footer {
    width: 100%;

    @media (min-width: 768px) {
        width: auto;
        margin: 0 0 0 1rem;
    }
}
