.used-cars {
    flex-direction: column-reverse;
    // margin-left: -30px;
    // margin-right: -30px;

    @media (min-width: 768px) {
        flex-direction: row;
        margin-left: initial;
        margin-right: initial;
    }
}
