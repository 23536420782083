:root {
    --bs-body-color: #{$body-color};
    --bs-bg-opacity: .2;
    --link-color: #{$primary};
    --navbar-height: 77px;
    --form-bg: #fff;

    --form-bg-light-opacity: rgba(255, 255, 255, .2);
    --form-bg-dark-opacity: rgba(0, 0, 0, .1);

    @media (min-width: 992px) {
        --navbar-height: 68px;
    }
}

select:invalid,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
::placeholder {
    color: #6d97ae;
    opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
    -moz-appearance: textfield;
}

label {
    font-weight: inherit;
}

// * {
//     color: var(--bs-body-color, #{$body-color});
// }

.page-header {
    margin-top: 30px;
    margin-bottom: 40px;
}

.gray {
    color: $gray;
}

// .input-group-transparent {
//     background-color: rgba(255, 255, 255, .1);
//     border-radius: $border-radius;

//     .btn {
//         border-radius: $border-radius !important;
//     }
// }

// .input-group-transparent .input-group-text,
// .input-group-transparent .form-control,
// .input-group-transparent .form-select {
//     // background-color: rgba(255, 255, 255, 0.1);
//     background-color: transparent;
//     color: var(--bs-body-color);
// }

.ul-style-check {
    // list-style-image: url("/img/li.svg");
    list-style: none;
    padding-left: 0;

    li {
        background-image: url(/img/li.svg);
        background-repeat: no-repeat;
        background-position: left top;
        padding: 2px 0 18px 33px;
    }
}

@media (min-width: 992px) {
    .ul-2columns {
        columns: 2;
    }
}

.link-inherit a {
    color: inherit;
}

.overflow-hidden {
    overflow: hidden;
}

.z-2 {
    position: relative;
    z-index: 2;
}

.text-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-gray {
    background-color: rgba(#9EABB8, .2);
}

.bkeys {
    background-color: #B19CE4;
    background-image: url('/img/b-8.svg'), url('/img/b-8.png'), linear-gradient(to bottom, $primary 80%, #B19CE4 80%);
    background-position: top center, bottom center, center;
    background-repeat: no-repeat;
    background-size: 100% auto, 100% auto, cover;
    height: 100%;
    margin-left: -15px;
    margin-right: -15px;
    min-height: 500px;

    @media (min-width: 768px) {
        margin-left: 0;
        margin-right: 0;
        max-width: 450px;
        min-height: initial;
    }
}

@media (min-width: 768px) {
    .form-questions {
        background-image: url(/img/form-question.webp), linear-gradient(90deg, #ECEEF1 8.28%, rgba(236, 238, 241, 0) 100%);
        background-position: right center;
        background-size: cover 50%;
        background-repeat: no-repeat;
    }
}

.drop-shadow {
    box-shadow: 0 4px 20px rgba(0, 0, 0, .25);
}

.text-link,
.text-link:hover {
    color: #2F80ED;
}

.text-link:hover {
    text-decoration: none;
}

.ul-progress {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
}

.ul-progress li {
    opacity: .5;
}

.ul-progress li:nth-child(n+2):before {
    content: '';
    display: inline-block;
    width: 30px;
    height: .8rem;
    background-image: radial-gradient(circle at center, rgba(255, 255, 255, 0.3) 0px, rgba(255, 255, 255, 0.3) 2px, transparent 3px);
}

.ul-progress .current,
.ul-progress .current a {
    opacity: 1;
}

.bg-opacity {
    --bs-bg-opacity: .2;
}

.hr-style {
    width: 40px;
    border-radius: 3px;
    border: 2px solid $primary;
    background-color: $primary;
    opacity: 1;
}

.r20 {
    border-radius: 20px;
}

.form-check-input-sm {
    width: 16px;
    height: 16px;
}

.btn-dropdown {
    border: 1px solid #F4F6F8;
    border-radius: 8px;
    margin-bottom: 6px;
    width: 100%;
    color: var(--bs-body-color);
    text-align: left;
    padding: 15px;
}

.btn-dropdown:hover {
    color: var(--bs-body-color) !important;
    border-color: var(--bs-primary);
}

.fz-inherit {
    font-size: inherit;
}

.flex-grow {
    flex: 1 0 auto;
}

.fs-inherit {
    font-size: inherit !important;
}

.anim-new {
    animation: anim-new 3s ease-in 1
}

@keyframes anim-new {
    0% { background-color: #fa0 }
    100% { background-color: #fa00 }
}
