.creditcond {
    background-image: url(/img/b7.jpg);
    background-size: cover;
    padding-top: 61px;
    color: #fff;
}

.creditcond-card {
    background-color: #fff;
    color: var(--bs-body-color);
    margin-bottom: -8px;
    padding: 3rem 2rem 8px;

    @media (min-width: 992px) {
        border-radius: 8px;
    }
}

.creditcond-service {
    border: 1px solid #F4F6F8;
    padding: 20px 20px 15px;
    display: block;
    text-decoration: none;
    color: var(--bs-body-color);
}
