$primary: #017e67;
$secondary: #9EABB8;
$success: #6FCF97;
// $info: #0dcaf0;
// $warning: #ffc107;
// $danger: #dc3545;
$light: rgba(255,255,255,.2);
$dark: #152026;
$gray: #756F6C;
$transparent: rgba(255, 255, 255, 0.2);

$font-weight-medium: 600;

$table-bg-scale: -80%;
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
//   "success":    shift-color($success, $table-bg-scale),
//   "info":       shift-color($info, $table-bg-scale),
//   "warning":    shift-color($warning, $table-bg-scale),
//   "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
  "gray":       $gray,
  "opacity":    $transparent,
);

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
  "dark":       $dark,
  "opacity":    $transparent
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 988px,
);

$body-bg: #ffffff;
$body-color: $dark;
$font-family-base: Commissioner, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-root: 14px;
$line-height-base: 1.57;

$h1-font-size: 72px;
$h2-font-size: 42px;
$h3-font-size: 38px;
$h4-font-size: 28px;
$h5-font-size: 24px;
$h6-font-size: 20px;
$headings-line-height: 1.3;
$headings-font-weight: 700;

$zindex-dropdown:           15;
$zindex-sticky:             16;
$zindex-fixed:              17;
$zindex-offcanvas-backdrop: 18;
$zindex-offcanvas:          19;
$zindex-modal-backdrop:     20;
$zindex-modal:              21;
$zindex-popover:            22;
$zindex-tooltip:            23;

// $input-bg: var(--form-bg-dark-opacity, #fff);
// $input-border-color: $input-bg;
// $input-focus-bg: $input-bg;
// $input-focus-color: #000;
// $form-select-bg: $input-bg;
// $form-select-border-color: $input-bg;
// $form-select-focus-border-color: $input-bg;
// $form-select-color: #000;

// $input-border-width: 0;
// $input-group-addon-bg: $input-bg;

$border-radius: 8px;
$btn-border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;

// $input-placeholder-color: $gray;

$btn-link-color: #fff;
$btn-link-disabled-color: #fff;

// $form-switch-width: 3.14em;
// $form-check-input-width: 1.57em;

$form-select-bg-size: 11px 7px;
$form-select-indicator-color: $dark;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"11\" height=\"7\" fill=\"none\"><path fill-rule=\"evenodd\" d=\"M9.22.22L10.28 1.28l-5.03 5.03L.22 1.28 1.28.22l3.97 3.97L9.22.22z\" fill=\"#{$form-select-indicator-color}\"/></svg>");

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: 60px,
  7: 120px,
) !default;

$form-check-input-width:                  1em;
$form-check-min-height:                   1em;
$form-check-padding-start:                0;
$form-switch-width:                       44px;
$form-switch-padding-start:               54px;

$border-radius: 8px;
